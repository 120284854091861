$(function () {
    $('.modal').on('show.bs.modal', function (event) {
        let $form = $('form', this);
        let $button = $(event.relatedTarget);
        form_dynamic_fill_data($form, $button);
    });

    $('form.form-dynamic').on('submit', function (event) {
        let $button = $(event.originalEvent.submitter);
        form_dynamic_fill_data($(this), $button);
    });

    function form_dynamic_fill_data($form, $button) {
        let formName = $form.attr('name');
        let data = $button.data();

        $.each(data, function (dataName, dataValue) {
            console.log(dataName + ' ' + dataValue);
            if (dataName === 'url') {
                $form.attr('action', dataValue);

                return;
            }

            let $field = $form.find('[name="' + formName + '[' + dataName + ']"]');
            if ($field.is('input')) {
                let type = $field.attr('type');

                if (type === 'radio') {
                    $.each($field, function (k, v) {
                        if ($(v).val() === dataValue) {
                            $(v).attr('checked', true);
                        }
                    });
                } else if (type === 'checkbox') {
                    $field.prop('checked', dataValue);
                } else {
                    $field.val(dataValue);
                }
            } else if ($field.is('select')) {
                $field.val(dataValue);
            }
        });
    }
});
