$(function () {
    //włączanie tooltipów
    $('[data-toggle="tooltip"]').tooltip();

    //scrollbar
    $('.scrollbarBox').mCustomScrollbar({
        mouseWheel: {preventDefault: true}
    });

    /*POPOVERS*/
    $('.popover-with-html').popover({
        html: true,
        content: function () {
            return $(this).find('+ .popover-content-wrapper').html();
        }
    });
});
