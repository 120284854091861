/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './scss/global.scss';

// start the Stimulus application
import './bootstrap';

const $ = require('jquery');
global.$ = global.jQuery = $;

require('jquery-mousewheel');
require('malihu-custom-scrollbar-plugin');
require('./js/lib/bootstrap.bundle.js');
require('./js/global.js');
require('./js/modules/forms.js');
